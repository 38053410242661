<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <Logo v-if="windowWidth <= 992" /> -->
        <Logo />

       
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5" >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to The Fives Club! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>

          <b-alert
            variant="warning"
            show
            v-if="false"
          >
            <div class="alert-body font-small-2" >
              <p>
                <small class="mr-50"><span class="font-weight-bold">Usuario:</span> userdemo@mail.com</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Contraseña:</span> passwordDemo </small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'Esto es solo para propositos de prueba'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert>
          <!-- form -->
          <Login/> 
          
            <b-col lg="12" class="d-lg-flex align-items-center pt-5 " >
              <div  class="w-100  text-center phoneContent">
                <h4>We're here</h4>
              <div>   
                <hr> <span class="icons phoneAds"><font-awesome-icon icon="fa-solid fa-phone" /></span>
              </div>
              </div>
            </b-col>  
            <b-row>
              <b-col
                lg="6"
                class="d-lg-flex align-items-center pt-1 "
              >
                <div  class="w-100  text-center phoneContent">         
                  <h6>Call Center</h6>
                  <div  class=" ulClass">
                    <ul class="text-left">
                      <li>
                        <a href="tel:+529842420151" target="_blank">+52 984 242 0151</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </b-col>
              <b-col
                lg="6"
                class="d-lg-flex align-items-center pt-1 "
              >
                <div  class="w-100  text-center phoneContent">                  
                    <h6>Toll Free</h6>
                    <div  class=" ulClass pl-1">
                      <ul class="text-left">
                      <li>From Mexico:  <a href="tel:8006811588" target="_blank">
                            800 681 1588
                            </a> </li>
                      <li>USA &amp; Canada:  <a href="tel:18668653664" target="_blank">
                            1 (866) 865-3664
                            </a> </li>
                      </ul>
                    </div> 
                </div>
              </b-col>
          </b-row>
        </b-col>
      </b-col>    
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapState } from 'vuex'
import Logo from '@/modules/layouts/components/Logo.vue'
import Login from '@/modules/auth/components/Login'
import store from '@/store/index'

export default {
  components: {  
    Logo,
    Login
  },  
  data() {
    return {
      sideImg: require('@/assets/images/pages/login-v2-dark.svg'),      
    }
  },
  computed: { 
		...mapState('appConfig',['windowWidth']),

    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },  
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
  @media screen and (max-width: 767px){
   .auth-wrapper.auth-v2 .brand-logo {
      position: relative;
      left: 0;
      margin: 0 auto;    
    }
  .auth-wrapper .brand-logo {
    display: flex;
    justify-content: center;
    // flex-direction: column;
  }
  
  }

.brand-logo svg {
  height: 125px;
  width: 250px;
}
span.icons.phoneAds {
  position: absolute;
  top: 27px;
  background: #fff;
  padding: 0 3px 0px 4px;
  }

.phoneContent {
    position: relative;
}



.ulClass {
    display: inline-block;
}

.ulClass ul {
    padding: 0 0 0 9px;
    font-size: 12px;
}

.ulClass a:hover {
    /* display: inline-block; */
    opacity: 0.6;
}

</style>
