<template>
  <validation-observer ref="loginValidation" v-slot="{ invalid }">
    <b-form class="auth-login-form mt-2" @submit.prevent>
      <!-- email -->
      <b-form-group label="Email" label-for="email-customer">
        <validation-provider
          #default="{ errors }"
          name="Email"
          rules="required|email"
        >
          <b-form-input
            id="email-customer"
            v-model="username"
            :state="errors.length > 0 ? false : null"
            name="email-customer"
            placeholder="john@thefives.com.mx"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- forgot password -->
      <b-form-group>
        <div class="d-flex justify-content-between">
          <label for="login-password">Password</label>
        </div>
        <validation-provider
          #default="{ errors }"
          name="Password"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid' : null"
          >
            <b-form-input
              id="login-password"
              v-model="password"
              :state="errors.length > 0 ? false : null"
              class="form-control-merge"
              :type="passwordFieldType"
              name="login-password"
              placeholder="********"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- submit buttons -->
      <b-button
        type="submit"
        variant="primary"
        :disabled="invalid || isLoadingAuth"
        block
        @click="login"
      >
        <span v-if="isLoadingAuth">
          <b-spinner variant="success" style="width: 1.5rem; height: 1.5rem" />
          Login in process...
        </span>
        <span v-if="!isLoadingAuth">
          Sign in <font-awesome-icon icon="fa-solid fa-right-to-bracket" />
        </span>
      </b-button>
      <p class="text-center mt-2">
        <b-link :to="{ name: 'auth-forgot-password' }">
          🔒 Forgot Password? | Reset your password
          <feather-icon icon="ArrowRightIcon" />
        </b-link>
      </p>
    </b-form>
  </validation-observer>
</template>


<script>
/* eslint-disable global-require */
import { mapActions, mapMutations } from "vuex";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { showAlertMessage, toJson } from "@/helpers/helpers";
import { utils } from "@/modules/owners/mixins/utils" 

export default {
	mixins: [togglePasswordVisibility, utils],
	data() {
		return {
			status: "",
			password: "",
			username: "",
			// validation rulesimport store from '@/store/index'
			required,
			email,
			isLoadingAuth: false,
			// mockUser,
		};
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
		},
	},
  	methods: {
		...mapActions('owners', ['pushActionsService', 'getGenericParameters']),
		...mapActions("auth", ["signIn", "getContractsUser"]),
		...mapMutations("auth", ["setUser", "setAdmin", "SetViewModules"]),
		async login() {
			this.isLoadingAuth = true;
			const isValid = await this.$refs.loginValidation.validate();
			if (isValid) {
				const payload = {
					user: this.username.trim(),
					password: this.password.trim(),
				}; //con trim remuevo espacios vacios
				
				const { ok, user, message } = await this.signIn(payload)

				if (ok) {
          
				  const userData = toJson(user)
          userData.languageCode = user.languagecode ? user.languagecode : 'ENG'
          userData.languageName = user.languagename ? user.languagename : 'English'
          this.$i18n.locale = userData && userData.languageName === "English" ? 'en' : 'es'
          delete userData.languagecode
          delete userData.languagename

					this.setUser(userData);
					this.setAdmin({...userData})

          const showMyProperty = await this.getGenericParameters(12)
          const showTheFivesVacations = await this.getGenericParameters(13)
          const showExchanges = await this.getGenericParameters(16)
          // const parameterResponse = await this.getGenericParameters(13)
          // const avaiableModules = parameterResponse?.value ? JSON.parse(parameterResponse?.value) : null

          this.SetViewModules({
            myProperty: Boolean(parseInt(showMyProperty?.value || 0)),
            promotions: Boolean(parseInt(showTheFivesVacations?.value || 0)),
            exchanges: Boolean(parseInt(showExchanges?.value || 0))
          })

					const redirectPage = user.isAdmin != null ? "admin" : "home"
					this.$router.push({ name: redirectPage });
					await this.pushData('onClick', 'makeLogin', 'makeLogin', this.$route.name ) // push a back del registro
				} else {
					// const message = 'Datos incorrectos'
					showAlertMessage(
						"Error login",
						"InfoIcon",
						message,
						"danger",
						4000,
						"bottom-right"
					);
				}
			}
			this.isLoadingAuth = false;
		},			
	},
};
</script>
